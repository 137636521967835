<template>
  <v-card elevation="0" class="order-info-summary">
    <v-row class="justify-space-between">
      <v-col class="left-block" cols="12" sm="7">
        <!-- <div>
          Servizio selezionato:
          <strong>{{
            $t(
              "orders.list.decodeAddressType." +
                order.shippingAddress.addressType.toString()
            )
          }}</strong>
        </div> -->
        <div>
          Indirizzo di consegna:
          <strong>
            {{
              $t(
                "navbar.address." +
                  order.shippingAddress.addressTypeId +
                  ".format",
                order.shippingAddress
              )
            }}</strong
          >
        </div>
        <div>
          Giorno e orario di consegna: <strong>{{ formatDate(order) }}</strong>
        </div>
        <div v-if="order.orderInfos && order.orderInfos.order_comment">
          <strong>Note:</strong><br />
          {{ order.orderInfos.order_comment }}
        </div>
      </v-col>
      <v-col class="right-block" cols="12" sm="5">
        <div>
          <strong>RIEPILOGO ORDINE</strong>
        </div>
        <div>
          Tipo di pagamento<span>{{
            $t("orders.paymentType." + order.paymentTypeId)
          }}</span>
        </div>
        <div>
          Totale ordine<span>{{
            $n(delivered ? order.deliveredNetTotal : order.netTotal, "currency")
          }}</span>
        </div>
        <div v-if="totalGifts > 0">
          Totale buoni
          <span>
            {{ $n(totalGifts, "currency") }}
          </span>
        </div>
        <div>
          Totale IVA<span>{{ $n(order.taxTotal, "currency") }}</span>
        </div>

        <div>
          <strong>TOTALE</strong
          ><strong class="gross-total">{{
            $n(
              delivered ? order.deliveredGrossTotal : order.grossTotal,
              "currency"
            )
          }}</strong>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped lang="scss">
.order-info-summary {
  .row {
    padding: 15px;
  }
  font-size: 14px;
  line-height: 2;
  margin: 25px 0;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .left-block {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .right-block {
    span {
      float: right;
    }
  }
  .gross-total {
    float: right;
    color: $primary;
    font-size: 16px;
  }
}
</style>
<script>
export default {
  name: "OrderInfoSummary",
  props: {
    order: { type: Object, required: true },
    delivered: { type: Boolean, default: false }
  },
  methods: {
    formatDate(order) {
      console.log("OK", order);
      let day = order.timeslot.date + " " + order.timeslot.beginTime;
      let beginFormat = this.$dayjs(day, {
        format: "YYYY-MM-DD h:mm:ss A"
      });

      if (beginFormat.isValid()) {
        let deliveryPeriod = beginFormat.format("DD MMMM | ");
        deliveryPeriod += order.timeslot.name;
        return deliveryPeriod;
      }
      return day;
    }
  },
  computed: {
    totalGifts() {
      let total = 0;
      if (this.order.giftCertificates) {
        total = this.order.giftCertificates
          .map(gift => gift.total)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      }
      return total;
    }
  }
};
</script>
