<template>
  <v-card class="order-info">
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="6" md="2">
          <span>N° Ordine</span>
        </v-col>
        <v-col cols="6" md="2">
          <span>Stato</span>
        </v-col>
        <v-col cols="4" md="2"></v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="align-center">
        <!-- N° ordine -->
        <v-col cols="6" md="2">
          <span class="order-number">#{{ order.orderId }}</span>
          <div class="delivery-type d-flex">
            <v-tooltip v-if="order.hrAgent && order.hrAgent.hrId" right>
              <template v-slot:activator="{ on, attrs }">
                <img
                  class="agent-icon"
                  src="/img/agent.svg"
                  alt="Agente"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              {{ $t("orders.list.agentTooltip") }}
            </v-tooltip>
            <!-- <img
              class="delivery-order-img"
              :src="
                '/services-icons/' +
                  $t(
                    'orders.list.decodeAddressTypeImg.' +
                      order.shippingAddress.addressType
                  ) +
                  '.svg'
              "
              alt="Delivery service"
            /> -->
            <!-- <div class="d-flex flex-column">
              <strong>{{
                $t(
                  "orders.list.decodeAddressType." +
                    order.shippingAddress.addressType
                )
              }}</strong>
              <img src="/img/underline.png" />
            </div> -->
          </div>
        </v-col>
        <!-- stato -->
        <v-col cols="6" md="2">
          <OrderStatusBadge v-bind:order="order" />
        </v-col>
        <!-- fattura -->
        <v-col cols="4" md="2">
          <!-- {{ order.orderInfos.need_invoice === "true" ? "Si" : "No" }} -->
        </v-col>
        <!-- buttons -->
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-md-end buttons-container justify-start"
        >
          <OrderQrCode
            v-if="order.orderInfos.PIN_CODE"
            :codeValue="order.orderInfos.PIN_CODE"
            :width="40"
            :height="40"
          />
          <v-tooltip bottom v-if="!order.isEditable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="main-button reorder-button mr-1"
                @click="addAllToCart(order.orderId)"
              >
                ORDINA DI NUOVO
                <v-icon class="ml-2">$cart</v-icon>
              </v-btn>
            </template>
            <span>Aggiungi tutto al carrello</span>
          </v-tooltip>
          <v-tooltip bottom v-if="order.orderStatusId == 8">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                v-on="on"
                icon
                class="secondary-icon-button mr-1"
                target="_blank"
                :href="receiptUrl"
                @click.prevent="
                  handleReceiptDownload(receiptUrl, order.orderId)
                "
              >
                <v-icon>mdi-receipt</v-icon>
              </v-btn>
            </template>
            <span>Scarica fattura o scontrino</span>
          </v-tooltip>
          <v-tooltip bottom v-if="order.isEditable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                v-bind="attrs"
                depressed
                v-on="on"
                icon
                class="secondary-icon-button mr-1"
                @click="editOrder(order.orderId)"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>Modifica ordine</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="
              order.orderStatusId != 11 &&
                !(order.orderStatusId == 1 && order.paymentTypeId == 1)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="secondary-icon-button mr-1"
                @click="printOrder"
              >
                <v-icon>mdi-printer-outline</v-icon>
              </v-btn>
            </template>
            <span>Stampa ordine</span>
          </v-tooltip>
          <v-tooltip bottom v-if="order.isPayable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="secondary-icon-button"
                @click="showPayment"
              >
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-btn>
            </template>
            <span>Ritenta pagamento</span>
          </v-tooltip>

          <v-tooltip bottom v-if="order.isDeletable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="secondary-icon-button ml-1"
                @click="deleteOrder(order.orderId)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Annulla ordine</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderQrCode from "./OrderQrCode";
import downloadDocumentMixin from "~/mixins/downloadDocument";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode },
  props: ["order"],
  computed: {
    receiptUrl() {
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=12&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      window.print();
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
