<template>
  <div class="qty-box d-flex flex-row">
    <!-- <span class="qnt">{{ item.quantity }}</span> -->
    <span class="val">{{ deliveredVal }} {{ unit }}</span>
    <span class="small">{{ quantityPerUnit }}</span>
  </div>
</template>
<script>
import productMixin from "~/mixins/product";
import CatalogService from "~/service/catalogService";

export default {
  name: "OrderDetailQuantity",
  // props: ["item"],
  data() {
    return {
      deliveredVal: null
    };
  },
  created: function() {
    if (this.delivered) {
      if (this.item.product.productInfos.TIPOLOGIA === "Sfuso") {
        //Handling floating point decimals issues
        let val = (this.item.deliveredWeight * 1000).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 1000;
        this.deliveredVal = val;
      } else {
        this.deliveredVal = this.item.deliveredQuantity;
      }
    } else {
      this.deliveredVal = this.quantity;
    }
  },
  props: {
    item: { type: Object, required: true },
    delivered: { type: Boolean, default: true }
  },
  mixins: [productMixin],
  computed: {
    product() {
      return this.item.product;
    }
  }
};
</script>

<style></style>
