<template>
  <div class="detail-table t-order-detail pa-0 pa-sm-4 rounded-lg">
    <v-row
      no-gutters
      v-if="!$vuetify.breakpoint.smAndDown"
      class="table-header"
      align="center"
    >
      <v-col cols="6" sm="6" class="pl-0 pl-sm-4">
        {{ $t("orders.orderDetail.header.product") }}
      </v-col>

      <v-col cols="2" sm="2" class="d-flex justify-center">
        {{ $t("orders.orderDetail.header.priceUnit") }}
      </v-col>
      <v-col cols="4" sm="2" class="d-flex justify-center">
        {{ $t("orders.orderDetail.header.qtyOrdered") }}<br />
        {{ $t("orders.orderDetail.header.qtyPrepared") }}
      </v-col>
      <v-col cols="2" sm="2" class="d-flex justify-center">
        {{ $t("orders.orderDetail.header.total") }}
      </v-col>
    </v-row>

    <v-list class="t-detail-list">
      <v-list-item
        v-for="item in items"
        v-bind:key="item.itemId"
        :to="
          item.product.productInfos.PRODUCT_NATURE_ID !== '8'
            ? {
                name: 'Product',
                params: { productSlug: item.product.slug }
              }
            : ''
        "
        class="order-item"
        :class="[item.deliveredType]"
      >
        <!-- prodotto -->
        <v-row no-gutters align="center">
          <v-col
            cols="12"
            v-if="item.orderItemReplacedId"
            class="pl-16 body-2 grey--text text--lighten-1"
          >
            <v-icon>
              mdi-swap-vertical
            </v-icon>
            SOSTITUITO CON
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center mb-3 mb-md-0">
            <img
              :src="item.product.mediaURL"
              class="product-img"
              width="85px"
              height="85px"
              max-height="85px"
              max-width="85px"
            />
            <div class="product-info">
              <span class="product-name">{{ item.product.name }}</span>
              <span class="product-brand">{{ item.product.shortDescr }}</span>
              <div class="product-descr">{{ item.product.description }}</div>
            </div>
          </v-col>
          <!-- prezzo unitario -->
          <v-col
            cols="4"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center product-info"
          >
            <span
              class="order-info-title price-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.priceUnit')"
            >
            </span>
            <span class="unit-price text-center">
              {{ item.unitPrice | currency }}</span
            >
            <!-- <ProductPrice class="justify-center"
              :product="item.product"
              :showStandard="false"
            ></ProductPrice> -->
          </v-col>
          <!-- quantità ordinata e quantità preparata -->
          <v-col
            cols="5"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center align-center product-info text-center"
          >
            <span class="order-info-title hidden-md-and-up">
              {{ $t("orders.orderDetail.header.qtyOrdered") }}/<br />
              {{ $t("orders.orderDetail.header.qtyPrepared") }}
            </span>
            <div class="d-flex flex-row flex-md-column align-center">
              <OrderDetailQuantity :item="item" :delivered="false" />
              <span class="d-flex d-md-none">/</span>
              <OrderDetailQuantity :item="item" :delivered="true" />
            </div>
          </v-col>
          <!-- prezzo totale -->
          <v-col
            cols="3"
            sm="4"
            md="2"
            class="d-flex flex-column justify-center product-info text-center"
          >
            <span
              class="order-info-title hidden-md-and-up"
              v-html="$t('orders.orderDetail.header.total')"
            >
            </span>
            <strong class="gross-total">
              {{
                $n(
                  delivered ? item.deliveredGrossTotal : item.grossTotal,
                  "currency"
                )
              }}
            </strong>
          </v-col>
          <v-col
            cols="12"
            class="text-left"
            v-if="isMasterAccount && item.orderItemInfos.user_notes"
          >
            <strong>Note:</strong> {{ item.orderItemInfos.user_notes }}
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
// import ProductPrice from "@/components/product/ProductPrice.vue";
import OrderDetailQuantity from "@/components/orders/OrderDetailQuantity.vue";
import { mapGetters } from "vuex";

export default {
  name: "OrderDetailTable",
  props: {
    items: { required: true, type: [] },
    delivered: { required: false, default: false }
  },
  components: { OrderDetailQuantity },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount"
    })
  },
  mounted() {
    // this.fetchCommentOrder();
  }
};
</script>
